import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import { AppTheme } from "../../models/AppTheme";

export interface RouteContainerProps {
  children: React.ReactNode;
}

export const RouteContainer: React.FC<RouteContainerProps> = ({ children }) => {
  const theme = useTheme<AppTheme | undefined>();
  const useStyles = createUseStyles((theme: AppTheme) => ({
    root: {
      display: "block",
      position: "relative",
      width: "100%",
      padding: theme?.layout?.gutter,
      backgroundColor: theme?.colors?.white,
    },
  }));
  const classes = useStyles({ theme });
  return <div className={classes.root}>{children}</div>;
};
