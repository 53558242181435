import React from "react";
import { createUseStyles, Styles, useTheme } from "react-jss";
import { SimpleSetupQrCode } from "../SimpleSetupQrCode";
import { AppTheme } from "../../models/AppTheme";

const useStyles = createUseStyles(
  (theme: AppTheme): Styles => ({
    root: {
      display: "flex",
      flexFlow: "row wrap-reverse",
      alignItems: "center",
      "& h2": {
        width: "100%",
        fontWeight: "bold",
        fontSize: 20,
        lineHeight: "30px",
        [theme.mediaQuery(theme.breakpoints.small)]: {
          width: "70%",
        },
      },
      "& p": {
        fontSize: 16,
        lineHeight: "24px",
        [theme.mediaQuery(theme.breakpoints.small)]: {
          width: "70%",
        },
      },
      "& img": {
        width: 200,
        height: 200,
        flex: "0 1 100%",
      },
    },
  })
);

export const SimpleSetupFallback: React.FC = () => {
  const theme: AppTheme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.root}>
      <SimpleSetupQrCode color={theme.colors.primary} />
      <div>
        <p>Connection to text services failed...</p>
        <h2>Have the customer scan this QR code</h2>
        <p>
          Alternatively, they may navigate on their mobile device to{" "}
          <strong>asur.me/simplesetup</strong>
        </p>
      </div>
    </div>
  );
};
