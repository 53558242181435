import { initTweekRepo } from "../tweek";
import { TweekRepository } from "tweek-local-cache";
import { analytics } from "@soluto-private/generic-analytics-writer";
import { UserBrowser } from "../UserBrowserId";

const defaultKeys = {};

declare global {
  interface Window {
    tweekRepo: TweekRepository;
  }
}

export default function initTweek(): void {
  const userBrowser = UserBrowser.Id
  analytics.updateProperties({ UserBrowserId: userBrowser })
  const context = {
    user_browser: {
      id: userBrowser,
      NodeEnv: process.env.NODE_ENV || "development",
    }
  };

  window.tweekRepo = initTweekRepo({
    context,
    defaultKeys,
    onError: err => console.error("Unable to fetch tweek keys", err),
  });
}
