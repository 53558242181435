import React from "react";

type Props = {
  size?: string;
  color?: string;
};

export const CircleIcon: React.FC<Props> = ({
  size = "8px",
  color = "black",
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4.5" cy="4.5" r="4.5" style={{ fill: color }} />
    </svg>
  );
};
