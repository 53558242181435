import {
  Context,
  createTweekClientWithFallback,
  FetchClientConfig,
  ITweekClient,
} from "tweek-client";
import { FlatKeys, TweekRepository } from "tweek-local-cache";

const TWEEK_URL = "https://tweek.mysoluto.com";
const TWEEK_FALLBACK_URL = "https://tweek-west.mysoluto.com";

let tweekRepository: TweekRepository;

export type InitTweekRepoOptions = FetchClientConfig & {
  context: Context;
  defaultKeys?: FlatKeys;
};

export type CreateTweekRepositoryConfig = {
  client: ITweekClient;
  context: Context;
  defaultKeys?: FlatKeys;
};

const createTweekClient = (config: FetchClientConfig) => {
  return createTweekClientWithFallback({
    ...config,
    urls: [TWEEK_URL, TWEEK_FALLBACK_URL],
  });
};

const createTweekRepository = ({
  client,
  context,
  defaultKeys,
}: CreateTweekRepositoryConfig) => {
  const tweekRepository = new TweekRepository({
    client,
    context: context,
  });

  if (defaultKeys) {
    tweekRepository.addKeys(defaultKeys);
    tweekRepository.expire();
  }

  return tweekRepository;
};

export const initTweekRepo = ({
  context,
  defaultKeys,
  ...config
}: InitTweekRepoOptions): TweekRepository => {
  const client = createTweekClient(config);
  tweekRepository = createTweekRepository({ client, context, defaultKeys });
  return tweekRepository;
};

export const getTweekRepository = (): TweekRepository => tweekRepository;
