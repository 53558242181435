import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import { AppTheme } from "../../models/AppTheme";
import { useTweekValue } from "react-tweek";

export const Header: React.FC = () => {
  const theme: AppTheme = useTheme();
  const useStyles = createUseStyles((theme: AppTheme) => ({
    root: {
      display: "flex",
      position: "relative",
      flexFlow: "row nowrap",
      justifyContent: "flex-start",
      alignItems: "center",
      width: "100%",
      padding: "15px 0",
      backgroundColor: theme?.colors?.white,
    },
    logoContainer: {
      display: "block",
      position: "relative",
      width: "100%",
      maxWidth: 100,
      height: "auto",
      marginLeft: "10px",
      "& img": {
        display: "block",
        position: "relative",
        width: "100%",
        maxWidth: 100,
        height: "auto",
      },
    },
    partnerLogos: {
      display: "flex",
      flexFlow: "row nowrap",
      justifyContent: "flex-start",
      alignItems: "center",
      marginLeft: "10px",
      "& img": {
        display: "block",
        position: "relative",
        width: "100%",
        maxWidth: 100,
        height: "auto",
      },
    },
    divider: {
      display: "block",
      marginLeft: "15px",
      marginRight: "5px",
      height: 40,
      width: 1,
      margin: "0 6px",
      backgroundColor: "#797979",
    },
  }));
  const classes = useStyles({ theme });

  const hubUrl = useTweekValue<string>(
    "nashville/trailhead/hub_url",
    "https://my.asurion.com"
  );

  return (
    <div className={classes.root}>
        {theme?.logos?.partner ? (
            <div className={classes.partnerLogos}>
              {theme?.logos?.partner}
            </div>
        ) : null}
      <div className={classes.divider} />
      <a href={hubUrl}>
        <div className={classes.logoContainer}>
          <div role="banner" data-testid="asurion-logo">{theme?.logos?.asurion}</div>
        </div>
      </a>
    </div>
  );
};
