import axios from "axios";
import { SendSmsBody } from "../models/TrailheadEngine";

const SMS_API_URL = process.env.REACT_APP_SMS_URL as string;

export const sendSms = async (data: SendSmsBody): Promise<SmsResponse> => {
  console.log(SMS_API_URL)
  const response = await axios({
    method: "POST",
    url: SMS_API_URL,
    data,
  });

  return response.data as SmsResponse;
}

export interface SmsResponse {
  commResponse?: {
    messagingStatus?: string
  }
}

