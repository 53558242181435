import React, { useEffect, useState } from "react";
import { createUseStyles, Styles, useTheme } from "react-jss";
import { AppTheme } from "../../models/AppTheme";
import { SimpleSetupFallback } from "../SimpleSetupFallback";
import { SimpleSetupInputSection } from "../SimpleSetupInputSection";
import { sendSms, trackEvent, UserBrowser } from "../../utilities";
import {
  TrailheadState,
} from "../../models/TrailheadEngine";
import { analytics } from "@soluto-private/generic-analytics-writer";

interface SimpleSetupTextInitiatorProps {
  trailheadState: TrailheadState;
}

const useStyles = createUseStyles(
  (theme: AppTheme): Styles => ({
    root: {
      display: "flex",
      flexFlow: "column wrap",
      "& h2": {
        width: "100%",
        fontWeight: "bold",
        color: theme.colors.primary,
        fontSize: 20,
        lineHeight: "30px",
        [theme.mediaQuery(theme.breakpoints.small)]: {
          width: "70%",
        },
      },
    },
    submitSection: {
      "& button": {
        padding: "10px 30px",
        background: theme.colors.primary,
        borderRadius: 999,
        color: theme.colors.secondary,
        "&:active": {
          background: theme.colors.tertiary,
        },
      },
      "& span": {
        marginLeft: 24,
        fontSize: 12,
      },
    },
  })
);

export const SimpleSetupTextInitiator: React.FC<SimpleSetupTextInitiatorProps> = ({
  trailheadState,
}: SimpleSetupTextInitiatorProps) => {
  const theme: AppTheme = useTheme();
  const classes = useStyles({ theme });
  const { storeId, mdn, repId } = trailheadState;
  const [sentPhoneNumber, setSentPhoneNumber] = useState<string | undefined>(
    undefined
  );
  const [sentStoreId, setSentStoreId] = useState<string | undefined>(undefined);
  const [sentRepId, setSentRepId] = useState<string | undefined>(undefined);
  const [showError, setShowError] = useState<boolean>(false);
  const [submitSucceeded, setSubmitSucceeded] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState<boolean>(false);
  const [showRequired] = useState<boolean>(false);
  const userBrowserId = UserBrowser.Id
  analytics.updateProperties({ UserBrowserId: userBrowserId })

  useEffect(() => {
    setSentPhoneNumber(mdn);
    setSentStoreId(storeId);
    setSentRepId(repId);
  }, [setSentPhoneNumber, setSentRepId, setSentStoreId, storeId, repId, mdn]);

  const onSendButtonClick = async () => {
    try {
      if (sentRepId && sentStoreId && sentPhoneNumber && userBrowserId) {
        trackEvent(
          "TextSubmit",
          { correlationId: userBrowserId },
        );
        setButtonLoading(true);
          const sendTextResponse = await sendSms({
            textPhoneNumber: sentPhoneNumber,
            sourceRefID1: sentRepId,
            sourceRefID2: sentStoreId,
            sourceRefID3: userBrowserId
          });
        if (
            sendTextResponse?.commResponse?.messagingStatus === "STAGED" ||
            sendTextResponse?.commResponse?.messagingStatus === "DELIVERED"
          ) {
            setSubmitSucceeded(true);
          } else {
            throw new Error("Text message not sent successfully");
          }
      }
    } catch (error) {
      console.error(error);
      setShowError(true);
      trackEvent(
        "TextSubmitError",
       { error },
      );
    }
  };

  return (
    <div className={classes.root}>
      {showError ? (
        <SimpleSetupFallback />
      ) : (
        <>
          {submitSucceeded ? (
            <h2>
              Text message successfully sent. Your customer will receive a text
              message with a link to a great digital onboarding experience soon!
            </h2>
          ) : (
            <>
              <h2>
                Enter the customer&apos;s preferred phone number to receive the
                text message:
              </h2>
              <SimpleSetupInputSection
                mdn={sentPhoneNumber}
                storeId={sentStoreId}
                repId={sentRepId}
                showStoreIdRepId={!storeId || !repId}
                setSentPhoneNumber={setSentPhoneNumber}
                setSentRepId={setSentRepId}
                setSentStoreId={setSentStoreId}
                showRequired={showRequired}
              />
              <div className={classes.submitSection}>
                <button onClick={onSendButtonClick} disabled={buttonLoading}>
                  {buttonLoading ? <>Loading...</> : <>Send Text Message</>}
                </button>
                <span>
                  Note: Please make sure customer&apos;s phone is activated
                  before sending this message.
                </span>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
