import { FullStoryAPI } from "react-fullstory";
import { trackEvent } from "./analytics";

// eslint-disable-next-line @typescript-eslint/ban-types
type FSNamespace = Record<string, Function>;

export const getFullstorySessionUrl = (): CallableFunction | null => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fsNamespace = window["FS" as any];
  if (fsNamespace) {
    const getCurrentSessionUrl = ((fsNamespace as unknown) as FSNamespace)[
      "getCurrentSessionURL"
    ];
    return getCurrentSessionUrl ? getCurrentSessionUrl() : null;
  }
  return null;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const fullstorySessionCreationEvent = (actionId: string) => {
  const sessionUrl = getFullstorySessionUrl();
  if (sessionUrl) {
    trackEvent(
      "FullstorySessionCreated",
      { ActionId: actionId },
    );
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setIdentityForFullstory = (id: string) => {
  try {
    FullStoryAPI("identify", id);
    fullstorySessionCreationEvent("UserUpdated");
  } catch (err) {
    console.error(err);
    trackEvent(
      "FullstoryIdentityError",
      { error: err, id },
    );
  }
};
