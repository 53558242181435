import React from "react";
import { createUseStyles } from "react-jss";

import errorImage from "./error-not-found.svg";

interface ErrorProps {
  message: string;
  styles?: Record<string, string>;
}

export const Error: React.FC<ErrorProps> = ({
  message,
  styles,
}: ErrorProps) => {
  const useStyles = createUseStyles({
    root: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      "& img": {
        alignSelf: "center",
      },
      "& *": {
        margin: "30px 0",
      },
      "& h3": {
        fontSize: 20,
        textAlign: "center",
      },
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.root} style={styles}>
      <img src={errorImage} alt="magnifying glass" />
      <h3>{message}</h3>
    </div>
  );
};
