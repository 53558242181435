import React from "react";

type props = { color: string };

/**
 * This is an SVG of a QR code that resolves to `asure.me/simplesetup`
 */
export const SimpleSetupQrCode: React.FC<props> = ({ color }) => {
  return (
    <svg
      version="1.1"
      baseProfile="tiny"
      xmlns="http://www.w3.org/2000/svg"
      width="200"
      height="200"
    >
      <rect
        shapeRendering="optimizeSpeed"
        x="0"
        y="0"
        width="200"
        height="200"
        fill="white"
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="12"
        y="12"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="19"
        y="12"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="26"
        y="12"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="33"
        y="12"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="40"
        y="12"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="47"
        y="12"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="54"
        y="12"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="68"
        y="12"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="82"
        y="12"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="96"
        y="12"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="110"
        y="12"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="124"
        y="12"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="138"
        y="12"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="145"
        y="12"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="152"
        y="12"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="159"
        y="12"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="166"
        y="12"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="173"
        y="12"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="180"
        y="12"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="12"
        y="19"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="54"
        y="19"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="75"
        y="19"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="82"
        y="19"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="89"
        y="19"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="96"
        y="19"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="138"
        y="19"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="180"
        y="19"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="12"
        y="26"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="26"
        y="26"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="33"
        y="26"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="40"
        y="26"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="54"
        y="26"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="68"
        y="26"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="75"
        y="26"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="82"
        y="26"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="103"
        y="26"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="110"
        y="26"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="138"
        y="26"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="152"
        y="26"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="159"
        y="26"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="166"
        y="26"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="180"
        y="26"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="12"
        y="33"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="26"
        y="33"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="33"
        y="33"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="40"
        y="33"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="54"
        y="33"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="68"
        y="33"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="75"
        y="33"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="82"
        y="33"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="96"
        y="33"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="103"
        y="33"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="110"
        y="33"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="117"
        y="33"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="124"
        y="33"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="138"
        y="33"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="152"
        y="33"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="159"
        y="33"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="166"
        y="33"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="180"
        y="33"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="12"
        y="40"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="26"
        y="40"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="33"
        y="40"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="40"
        y="40"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="54"
        y="40"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="68"
        y="40"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="89"
        y="40"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="96"
        y="40"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="117"
        y="40"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="124"
        y="40"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="138"
        y="40"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="152"
        y="40"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="159"
        y="40"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="166"
        y="40"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="180"
        y="40"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="12"
        y="47"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="54"
        y="47"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="82"
        y="47"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="89"
        y="47"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="103"
        y="47"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="138"
        y="47"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="180"
        y="47"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="12"
        y="54"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="19"
        y="54"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="26"
        y="54"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="33"
        y="54"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="40"
        y="54"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="47"
        y="54"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="54"
        y="54"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="68"
        y="54"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="82"
        y="54"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="96"
        y="54"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="110"
        y="54"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="124"
        y="54"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="138"
        y="54"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="145"
        y="54"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="152"
        y="54"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="159"
        y="54"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="166"
        y="54"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="173"
        y="54"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="180"
        y="54"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="82"
        y="61"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="96"
        y="61"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="110"
        y="61"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="124"
        y="61"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="12"
        y="68"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="19"
        y="68"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="26"
        y="68"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="33"
        y="68"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="54"
        y="68"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="68"
        y="68"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="82"
        y="68"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="89"
        y="68"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="96"
        y="68"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="103"
        y="68"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="124"
        y="68"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="131"
        y="68"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="152"
        y="68"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="159"
        y="68"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="166"
        y="68"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="180"
        y="68"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="12"
        y="75"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="19"
        y="75"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="26"
        y="75"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="47"
        y="75"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="68"
        y="75"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="82"
        y="75"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="96"
        y="75"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="103"
        y="75"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="124"
        y="75"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="145"
        y="75"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="173"
        y="75"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="26"
        y="82"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="40"
        y="82"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="54"
        y="82"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="68"
        y="82"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="75"
        y="82"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="82"
        y="82"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="89"
        y="82"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="96"
        y="82"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="103"
        y="82"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="124"
        y="82"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="131"
        y="82"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="138"
        y="82"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="145"
        y="82"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="159"
        y="82"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="12"
        y="89"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="19"
        y="89"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="47"
        y="89"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="61"
        y="89"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="75"
        y="89"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="82"
        y="89"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="117"
        y="89"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="124"
        y="89"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="131"
        y="89"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="145"
        y="89"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="159"
        y="89"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="166"
        y="89"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="173"
        y="89"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="180"
        y="89"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="54"
        y="96"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="61"
        y="96"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="75"
        y="96"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="82"
        y="96"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="96"
        y="96"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="110"
        y="96"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="117"
        y="96"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="124"
        y="96"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="131"
        y="96"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="138"
        y="96"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="145"
        y="96"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="152"
        y="96"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="166"
        y="96"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="180"
        y="96"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="26"
        y="103"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="33"
        y="103"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="47"
        y="103"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="68"
        y="103"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="75"
        y="103"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="89"
        y="103"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="96"
        y="103"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="110"
        y="103"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="117"
        y="103"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="124"
        y="103"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="131"
        y="103"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="138"
        y="103"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="145"
        y="103"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="152"
        y="103"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="180"
        y="103"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="19"
        y="110"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="40"
        y="110"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="54"
        y="110"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="68"
        y="110"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="75"
        y="110"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="89"
        y="110"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="103"
        y="110"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="131"
        y="110"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="138"
        y="110"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="152"
        y="110"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="173"
        y="110"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="12"
        y="117"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="33"
        y="117"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="47"
        y="117"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="61"
        y="117"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="68"
        y="117"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="75"
        y="117"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="89"
        y="117"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="110"
        y="117"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="117"
        y="117"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="124"
        y="117"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="131"
        y="117"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="138"
        y="117"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="145"
        y="117"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="152"
        y="117"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="159"
        y="117"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="173"
        y="117"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="180"
        y="117"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="26"
        y="124"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="33"
        y="124"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="47"
        y="124"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="54"
        y="124"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="96"
        y="124"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="110"
        y="124"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="124"
        y="124"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="131"
        y="124"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="138"
        y="124"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="145"
        y="124"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="152"
        y="124"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="166"
        y="124"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="173"
        y="124"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="68"
        y="131"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="75"
        y="131"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="82"
        y="131"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="110"
        y="131"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="124"
        y="131"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="152"
        y="131"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="159"
        y="131"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="180"
        y="131"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="12"
        y="138"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="19"
        y="138"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="26"
        y="138"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="33"
        y="138"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="40"
        y="138"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="47"
        y="138"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="54"
        y="138"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="75"
        y="138"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="82"
        y="138"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="103"
        y="138"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="110"
        y="138"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="124"
        y="138"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="138"
        y="138"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="152"
        y="138"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="159"
        y="138"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="166"
        y="138"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="173"
        y="138"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="180"
        y="138"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="12"
        y="145"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="54"
        y="145"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="89"
        y="145"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="103"
        y="145"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="110"
        y="145"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="117"
        y="145"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="124"
        y="145"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="152"
        y="145"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="159"
        y="145"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="180"
        y="145"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="12"
        y="152"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="26"
        y="152"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="33"
        y="152"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="40"
        y="152"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="54"
        y="152"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="82"
        y="152"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="103"
        y="152"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="110"
        y="152"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="124"
        y="152"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="131"
        y="152"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="138"
        y="152"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="145"
        y="152"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="152"
        y="152"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="12"
        y="159"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="26"
        y="159"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="33"
        y="159"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="40"
        y="159"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="54"
        y="159"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="68"
        y="159"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="75"
        y="159"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="82"
        y="159"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="117"
        y="159"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="131"
        y="159"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="138"
        y="159"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="152"
        y="159"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="166"
        y="159"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="173"
        y="159"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="180"
        y="159"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="12"
        y="166"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="26"
        y="166"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="33"
        y="166"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="40"
        y="166"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="54"
        y="166"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="68"
        y="166"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="75"
        y="166"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="82"
        y="166"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="110"
        y="166"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="117"
        y="166"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="124"
        y="166"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="138"
        y="166"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="152"
        y="166"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="159"
        y="166"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="173"
        y="166"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="12"
        y="173"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="54"
        y="173"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="68"
        y="173"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="89"
        y="173"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="96"
        y="173"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="103"
        y="173"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="110"
        y="173"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="159"
        y="173"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="166"
        y="173"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="12"
        y="180"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="19"
        y="180"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="26"
        y="180"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="33"
        y="180"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="40"
        y="180"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="47"
        y="180"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="54"
        y="180"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="68"
        y="180"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="82"
        y="180"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="89"
        y="180"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="138"
        y="180"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="145"
        y="180"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="159"
        y="180"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="166"
        y="180"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="173"
        y="180"
        width="7"
        height="7"
        fill={color}
      />
      <rect
        shapeRendering="optimizeSpeed"
        x="180"
        y="180"
        width="7"
        height="7"
        fill={color}
      />
    </svg>
  );
};
