import * as React from "react"

export const AsurionLogoBlack = (): JSX.Element => {
  return (
    <svg
      width={80}
      height={20}
      viewBox="0 0 80 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M34.207 5.959v7.203c0 1.919-.977 3.294-2.86 3.294-1.882 0-2.823-.977-2.823-3.294V5.96h-2.86v7.819c0 3.475 1.774 5.357 4.995 5.357 1.81 0 2.969-.869 3.584-1.63v1.485h2.86V5.959h-2.896zm25.52-.145c-3.693 0-6.661 3.004-6.661 6.66 0 3.692 3.004 6.66 6.66 6.66 3.692 0 6.66-3.004 6.66-6.66.037-3.692-2.968-6.66-6.66-6.66zm3.836 6.66a3.8 3.8 0 11-7.6.001 3.8 3.8 0 017.6 0zm-17.302-6.66c-1.05 0-2.607.217-3.801 1.52V5.96H39.6v13.03h2.86v-7.528a2.788 2.788 0 012.787-2.787c.362 0 .688.072 1.014.18v-3.04zM22.66 17.868c-.833.832-1.919 1.267-3.113 1.267-1.159 0-2.28-.47-3.113-1.267-.833-.833-1.267-1.919-1.267-3.113h2.715c0 .434.18.869.47 1.158.326.326.724.47 1.159.47.434 0 .868-.18 1.158-.47a1.65 1.65 0 00.036-2.316c-.398-.326-.688-.362-1.194-.471-.688-.145-1.593-.326-2.534-1.267-1.376-1.376-1.376-3.656 0-5.031 1.375-1.376 3.656-1.376 5.031 0 .145.144.254.29.398.47l-1.81 1.81c-.29-.326-.543-.507-.904-.543-.29-.072-.58 0-.76.217a.854.854 0 00-.254.58c0 .217.072.434.253.579.362.361.616.434 1.159.506.615.109 1.484.29 2.461 1.122l.073.073c1.737 1.737 1.737 4.524.036 6.226zM10.46 18.99h2.86v-6.516c0-3.692-3.005-6.66-6.66-6.66C3.003 5.814 0 8.782 0 12.474s3.004 6.66 6.66 6.66c1.267 0 2.679-.361 3.801-1.628v1.484zm-3.8-2.715a3.8 3.8 0 11-.002-7.6 3.8 3.8 0 01.001 7.6zm67.327-7.601a2.843 2.843 0 00-2.86 2.86v7.456h-2.86v-7.457c0-3.149 2.57-5.719 5.72-5.719s5.72 2.57 5.72 5.72v7.456h-2.86v-7.457a2.843 2.843 0 00-2.86-2.86zM48.36 5.959h2.86v13.03h-2.86V5.96zm3.367-3.403a1.947 1.947 0 01-1.955 1.955 1.947 1.947 0 01-1.955-1.955c0-1.086.869-1.954 1.955-1.954s1.955.868 1.955 1.954z"
        fill="#000"
      />
    </svg>
  )
}
