import React from "react";
import { createUseStyles, useTheme } from "react-jss";
import { AppTheme } from "../../models/AppTheme";
import { CircleIcon } from "../CircleIcon";

const useStyles = createUseStyles((theme: AppTheme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexFlow: "column wrap",
    marginBottom: 55,
    [theme.mediaQuery(theme.breakpoints.small)]: {
      width: "60%",
    },
  },
  header: {
    marginBottom: 46,
    "& h1": {
      fontSize: 48,
      lineHeight: "26px",
      color: theme.colors.primary,
      fontFamily: "SF Pro Display, ATT Aleck Sans, Apercu Pro",
    },
  },
  listItems: {
    display: "flex",
    flexFlow: "column wrap",
    "& h2": {
      fontWeight: "bold",
      color: theme.colors.primary,
      fontSize: 20,
      lineHeight: "30px",
      marginBottom: 15,
    },
    "& ul": {
      listStyle: "none",
      listStylePosition: "inside",
      textIndent: -10,
      lineHeight: "150%",
      padding: 12,
      "& li": {
        marginBottom: 26,
        fontSize: 20,
        lineHeight: "30px",
      },
    },
  },
  expertTimes: {
    "& h3": {
      fontSize: 16,
      lineHeight: "24px",
      fontWeight: "bold",
    },
    "& p": {
      fontSize: 16,
      lineHeight: "24px",
    },
  },
}));

export const SimpleSetupInstructions: React.FC = () => {
  const theme: AppTheme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <h1>
          Simple<strong>Setup</strong>
        </h1>
      </div>
      <div className={classes.listItems}>
        <h2>Share this information with the customer:</h2>
        <ul>
          <li>
            <CircleIcon size="9px" color={theme.colors.primary} />
            We&apos;ll send you a text message with instructions to set up your
            device.
          </li>
          <li>
            <CircleIcon size="9px" color={theme.colors.primary} />
            The instructions guide you through each step; showing you exactly
            how to set up your device. If you need help, you can easily message
            for support.
          </li>
          <li>
            <CircleIcon size="9px" color={theme.colors.primary} />
            By continuing, you agree to receive a text message at the number you
            provide. Data rates may apply.
          </li>
        </ul>
      </div>
      <div className={classes.expertTimes}>
        <h3>ProTechs are available 7 days a week</h3>
        <p>Monday thru Friday: 8 a.m. to 12 a.m. ET</p>
        <p>Saturday and Sunday: 10 a.m. to 10 p.m. ET</p>
      </div>
    </div>
  );
};
