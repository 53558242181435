import * as React from "react"

export const AttLogo = (): JSX.Element => {
  return (
    <svg
      width={83}
      height={35}
      viewBox="0 0 83 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.69 30.591a17.325 17.325 0 0010.53 3.555c4.338 0 8.293-1.588 11.312-4.194.036-.03.019-.054-.017-.03-1.354.89-5.216 2.838-11.295 2.838-5.282 0-8.621-1.16-10.508-2.2-.035-.018-.047.008-.021.031zm11.697.865c4.226 0 8.869-1.135 11.645-3.383.76-.611 1.483-1.426 2.132-2.523.375-.63.737-1.38 1.035-2.115.015-.035-.01-.054-.035-.014-2.583 3.745-10.063 6.082-17.785 6.082-5.459 0-11.33-1.72-13.63-5.003-.024-.03-.045-.017-.03.018 2.14 4.485 8.64 6.938 16.668 6.938zm-4.618-7.443C4.981 24.013.836 19.98.085 17.226c-.01-.04-.036-.03-.036.005 0 .926.095 2.124.257 2.915.077.386.396.994.864 1.476 2.127 2.185 7.434 5.248 16.623 5.248 12.518 0 15.38-4.109 15.964-5.46.42-.965.635-2.713.635-4.179 0-.355-.01-.639-.024-.919 0-.044-.026-.049-.036-.004-.625 3.308-11.321 7.705-20.563 7.705zM1.704 9.974C1.201 10.96.643 12.62.476 13.48c-.072.37-.04.545.09.82 1.054 2.204 6.382 5.728 18.81 5.728 7.582 0 13.473-1.835 14.427-5.185.176-.616.183-1.269-.04-2.145-.254-.98-.726-2.124-1.127-2.925-.014-.026-.035-.023-.03.01.147 4.393-12.285 7.226-18.558 7.226-6.794 0-12.463-2.669-12.463-6.038 0-.324.067-.646.152-.984.008-.033-.019-.038-.033-.012zM28.561 4.58c.071.11.107.23.107.39 0 1.88-5.836 5.204-15.128 5.204-6.825 0-8.104-2.495-8.104-4.083 0-.566.22-1.149.706-1.736.027-.035.005-.05-.026-.024-.887.74-1.7 1.574-2.416 2.474-.34.425-.553.801-.553 1.029 0 3.289 8.368 5.674 16.196 5.674 8.337 0 12.06-2.683 12.06-5.04 0-.843-.333-1.334-1.182-2.288a20.372 20.372 0 00-1.63-1.623c-.03-.021-.05-.003-.03.023zM26.004 2.7A17.17 17.17 0 0017.22.317c-3.243 0-6.322.895-8.905 2.455-.775.47-1.212.846-1.212 1.33 0 1.428 3.385 2.96 9.388 2.96 5.94 0 10.548-1.68 10.548-3.298 0-.383-.341-.653-1.035-1.062z"
        fill="#009FDB"
      />
      <path
        d="M76.902 24.464c-.217 0-.368-.157-.368-.383V12.839h-3.67c-.218 0-.368-.157-.368-.383v-1.55c0-.227.15-.384.368-.384h9.767c.218 0 .369.157.369.383v1.55c0 .227-.151.384-.369.384h-3.668V24.08c0 .226-.151.383-.369.383h-1.692zm-28.106-5.613l-1.927-5.752-1.943 5.752h3.87zm4.272 5.159c.084.226-.05.454-.285.454h-1.742c-.251 0-.402-.121-.486-.367l-.988-2.963h-5.41l-.99 2.963c-.084.244-.235.367-.487.367h-1.64c-.218 0-.369-.226-.285-.454l4.54-13.142c.084-.244.235-.348.485-.348h2.245c.252 0 .419.104.502.348l4.54 13.142zm13.012-1.43c1.072 0 1.791-.54 2.379-1.462l-2.713-3.033c-1.04.61-1.71 1.22-1.71 2.44a2.02 2.02 0 002.044 2.056zm.753-10.213c-.87 0-1.373.575-1.373 1.341 0 .594.301 1.116.988 1.882 1.189-.714 1.692-1.15 1.692-1.917 0-.713-.435-1.306-1.307-1.306zm7.423 11.609c.218.244.084.488-.185.488h-2.127c-.284 0-.437-.07-.62-.297l-1.273-1.464c-.854 1.186-2.044 2.075-4.021 2.075-2.446 0-4.373-1.534-4.373-4.166 0-2.023 1.04-3.103 2.614-4.01-.77-.925-1.122-1.9-1.122-2.753 0-2.161 1.458-3.644 3.652-3.644 2.245 0 3.619 1.377 3.619 3.416 0 1.743-1.206 2.718-2.48 3.452l1.877 2.11 1.055-1.918c.133-.226.284-.314.553-.314h1.625c.27 0 .42.193.252.489l-1.877 3.346 2.831 3.19zm-16.718.488c.218 0 .368-.157.368-.383V12.839h3.67c.217 0 .367-.157.367-.383v-1.55c0-.227-.15-.384-.368-.384h-9.767c-.218 0-.369.157-.369.383v1.55c0 .227.151.384.369.384h3.668V24.08c0 .226.15.383.368.383h1.694z"
        fill="#000"
      />
    </svg>
  )
}
