import {
  MixpanelProjects,
  analytics,
} from "@soluto-private/generic-analytics-writer";

export const trackEvent = (
  eventName: string,
  properties?: Record<string, unknown>,
): void => {
  const mixpanelProjects: MixpanelProjects[] = [MixpanelProjects.HOME];
  analytics.dispatch(eventName, mixpanelProjects, properties);
};
