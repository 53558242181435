import React from "react";
import { createUseStyles } from "react-jss";

export interface InputLabelProps {
  htmlFor: string;
  children: React.ReactNode;
  fontSize?: number;
}

export const InputLabel: React.FC<InputLabelProps> = ({
  htmlFor,
  children,
  fontSize = 24,
}: InputLabelProps) => {
  const useStyles = createUseStyles({
    root: {
      fontSize: fontSize,
    },
  });
  const classes = useStyles();
  return (
    <label htmlFor={htmlFor} className={classes.root}>
      {children}
    </label>
  );
};
