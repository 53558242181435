import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import { TweekProvider } from "react-tweek";
import { getTweekRepository } from "../../utilities/tweek";
import { createUseStyles, useTheme } from "react-jss";
import { ThemeProvider, attTheme } from "../ThemeProvider";
import { AppTheme } from "../../models/AppTheme";
import "../ThemeProvider/fonts/fonts.css";
import { Error } from "../Error";
import { SimpleSetupSplashPage } from "../../pages";

interface AppProps {
  initPromise: Promise<void>;
}
interface AppContainerProps {
  children: React.ReactNode;
}

const AppContainer: React.FC<AppContainerProps> = ({ children }) => {
  const theme = useTheme<AppTheme | undefined>();
  const useStyles = createUseStyles((theme: AppTheme) => ({
    root: {
      display: "block",
      position: "relative",
      width: "100%",
      minHeight: "100vh",
      padding: 0,
      fontFamily: theme.typography.fontFamily,
    },
  }));
  const classes = useStyles({ theme });
  return <div className={classes.root}>{children}</div>;
};

export const App: React.FC<AppProps> = ({ initPromise }: AppProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const urlParams = new URLSearchParams(window.location.search);


  useEffect(() => {
    initPromise.then(() => setIsLoading(false));
  }, [initPromise]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <TweekProvider value={getTweekRepository()}>
      <ThemeProvider theme={attTheme}>
        <Router>
          <AppContainer>
            <Routes>
              <Route
                path="/digital/*"
                element={<SimpleSetupSplashPage/>}
              />
              <Route
                path="/prod/opus"
                element={<Navigate replace={false} to={`/digital?${urlParams.toString()}`} />}
              />
              <Route
                path="/error"
                element={
                  <div>
                    <Error message="Oops, it seems we can't find what you are looking for." />
                  </div>
                }
              />
              <Route
                path="*"
                element={
              <Navigate to="/error" replace />} />
            </Routes>
          </AppContainer>
        </Router>
      </ThemeProvider>
    </TweekProvider>
  );
};
