import React from "react";
import { createUseStyles, Styles, useTheme } from "react-jss";
import { InputLabel } from "../InputLabel";
import { AppTheme } from "../../models/AppTheme";

interface SimpleSetupInputSectionProps {
  showRequired: boolean;
  mdn?: string;
  repId?: string;
  storeId?: string;
  showStoreIdRepId: boolean;
  setSentPhoneNumber: (phoneNumber: string) => void;
  setSentStoreId: (storeId: string) => void;
  setSentRepId: (repId: string) => void;
}

export const SimpleSetupInputSection: React.FC<SimpleSetupInputSectionProps> = ({
  showRequired,
  mdn,
  storeId,
  repId,
  showStoreIdRepId = false,
  setSentStoreId,
  setSentRepId,
  setSentPhoneNumber,
}) => {
  const useStyles = createUseStyles(
    (theme: AppTheme): Styles => ({
      root: {
        margin: "25px 0",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        [theme.mediaQuery(theme.breakpoints.small)]: {
          width: "50%",
        },
        "& input": {
          marginTop: 4,
          background: theme.colors.white,
          border: `1px solid ${theme.colors.black}`,
          boxSizing: "border-box",
          boxShadow: "3px 3px 0px rgba(0,0,0,0.25)",
          height: 48,
          fontSize: 16,
        },
      },
      required: {
        color: theme.colors.red,
        fontSize: 12,
      },
    })
  );

  const theme = useTheme<AppTheme | undefined>();
  const classes = useStyles({ theme });

  const validateInput = (input: string): boolean => {
    if (input.length === 0) return true
    const regex = new RegExp("^([a-z]|[A-Z]|[0-9])+$");
    return regex.test(input);
  };

  const onMdnChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (validateInput(ev.target.value)) {
      setSentPhoneNumber(ev.target.value);
    }
  };

  const onStoreIdChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (validateInput(ev.target.value)) {
      setSentStoreId(ev.target.value);
    }
  };

  const onRepIdChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    if (validateInput(ev.target.value)) {
      setSentRepId(ev.target.value);
    }
  };

  return (
    <div className={classes.root}>
      <InputLabel htmlFor="tel-input" fontSize={16}>
        Customer&apos;s Phone Number
      </InputLabel>
      <input
        className="fs-exclude"
        type="tel"
        id="tel-input"
        value={mdn || ""}
        onChange={onMdnChange}
      />
      {showRequired && <span className={classes.required}>Required</span>}
      {showStoreIdRepId && (
        <>
          <InputLabel htmlFor="store-input" fontSize={16}>
            OPUS ID
          </InputLabel>
          <input
            className="fs-exclude"
            type="text"
            id="store-input"
            value={storeId}
            onChange={onStoreIdChange}
          />
          {showRequired && <span className={classes.required}>Required</span>}
          <InputLabel htmlFor="rep-input" fontSize={16}>
            AT&amp;TUID
          </InputLabel>
          <input
            className="fs-exclude"
            type="text"
            id="rep-input"
            value={repId}
            onChange={onRepIdChange}
          />
          {showRequired && <span className={classes.required}>Required</span>}
        </>
      )}
    </div>
  );
};
