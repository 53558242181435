/** @jsxRuntime classic */
import "react-app-polyfill/ie11";
import "core-js";
import "regenerator-runtime/runtime";
import * as React from "react";
import ReactDOM from "react-dom";
import { App } from "./components";
import init  from "./utilities/initializations";

const initPromise = init();

ReactDOM.render(
  <React.StrictMode>
    <App initPromise={initPromise} />
  </React.StrictMode>,
  document.getElementById("root")
);

