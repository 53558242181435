import * as Sentry from "@sentry/react";
import { getTweekRepository } from "../tweek";

export default async function initSentry(): Promise<void> {
  const dsn = await getTweekRepository().getValue("nashville/trailhead/sentry_url");
  const NODE_ENV = process.env.NODE_ENV || "development";
  if (NODE_ENV === "production") {
    Sentry.init({
      dsn: dsn.value,
      autoSessionTracking: true,
    });
  }
}
