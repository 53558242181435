import React from "react";
import { createUseStyles, ThemeProvider as JSSProvider } from "react-jss";
import {
  AppTheme,
  Breakpoints,
  Colors,
  Logos,
  Typography,
  VendorColors,
} from "../../models/AppTheme";
import { AsurionLogoBlack, AttLogo } from "./resources";

interface ThemeProviderProps {
  children: React.ReactNode;
  theme: AppTheme;
  withReset?: boolean;
}
const logos: Logos = {
  asurion: AsurionLogoBlack(),
};

const attLogos: Logos = {
  ...logos,
  partner: AttLogo(),
};

const colors: Colors = {
  primary: "#E6E6EB",
  secondary: "#F0F0F5",
  tertiary: "#A5AAAF",
  black: "#000",
  error: "#DB1B1B",
  grayDark: "#333F48",
  grayLight: "#E6E6EB",
  grayLighter: "#F0F0F5",
  grayMedium: "#A5AAAF",
  green: "#37E7A7",
  purpleLight: "#F3E9FB",
  red: "#DB1B1B",
  success: "#37E7A7",
  white: "#fff",
};

const attColors: VendorColors = {
  ...colors,
  primary: "#0057B8",
  secondary: "#FFFFFF",
  tertiary: "#009FDB",
};

const typography: Typography = {
  fontFamily: `"Apercu Pro", "Apercu", Helvetica, sans-serif !important`,
  bodyColor: colors.black,
};

const breakpoints: Breakpoints = {
  small: 512,
  medium: 768,
  large: 1100,
};

const mediaQuery = (minWidth: number): string => {
  return `@media (min-width: ${minWidth}px)`;
};

const layout = {
  maxWidth: 1440,
  gutter: 16,
};

const transitions = {
  default: "400ms all ease",
};

export const attTheme: AppTheme = {
  logos: attLogos,
  colors: attColors,
  typography,
  breakpoints,
  mediaQuery,
  transitions,
  layout,
};

const useGlobalStyles = createUseStyles((theme: AppTheme) => ({
  "@global": {
    /* Eric Meyer Reset v2.0 */
    /* https://meyerweb.com/eric/tools/css/reset/ */
    "html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video": {
      margin: "0",
      padding: "0",
      border: "0",
      font: "inherit",
      verticalAlign: "baseline",
    },
    "article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section": {
      display: "block",
    },
    strong: {
      fontWeight: "bold",
    },
    "ol, ul": {
      listStyle: "none",
    },
    "blockquote, q": {
      quotes: "none",
    },
    "blockquote:before, blockquote:after, q:before, q:after": {
      content: "''",
    },
    table: {
      borderCollapse: "collapse",
      borderSpacing: "0",
    },
    /* Globals */
    "html, body": {
      width: "100%",
      minHeight: "100%",
      fontFamily: theme?.typography?.fontFamily || "sans-serif",
      color: theme?.typography?.bodyColor || "#000",
      backgroundColor: "#ffffff",
      "-webkit-font-smoothing": "antialiased",
      "-moz-osx-font-smoothing": "grayscale",
      margin: "0",
      padding: "0",
    },
    /* Box-sizing border-box */
    /* https://www.paulirish.com/2012/box-sizing-border-box-ftw/ */
    html: {
      boxSizing: "border-box",
      background: "transparent",
      fontSize: "100%",
    },
    "*, *:before, *:after": {
      boxSizing: "inherit",
    },
    code: {
      color: theme?.colors?.primary,
      fontFamily: "monospace",
      backgroundColor: theme?.colors?.grayLight,
      padding: 4,
    },
    a: {
      fontWeight: "bold",
      color: "inherit",
    },
    /* Reset `button` to nothing */
    /* https://gist.github.com/chrisheninger/a860f87ef4e529b2df606768b97665a8 */
    button: {
      display: "inline-block",
      "-webkit-appearance": "none",
      "-moz-appearance": "none",
      userSelect: "none",
      textDecoration: "none",
      fontFamily: "inherit",
      fontSize: "inherit",
      color: "inherit",
      cursor: "pointer",
      border: "none",
      backgroundColor: "transparent",
      borderColor: "transparent",
      padding: "0",
      margin: "0",
      "&:disabled": {
        opacity: 0.6,
        cursor: "not-allowed",
      },
    },
  },
}));

const GlobalsAndReset = (): null => {
  useGlobalStyles();
  return null;
};

export const ThemeProvider = ({
  children,
  theme,
  withReset = true,
  ...rest
}: ThemeProviderProps): JSX.Element => {

  return (
    <JSSProvider theme={theme} {...rest}>
      {withReset && <GlobalsAndReset />}
      {children}
    </JSSProvider>
  );
};
