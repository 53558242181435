import {
  SimpleSetupInstructions,
  SimpleSetupTextInitiator,
  Header,
  RouteContainer,
  FullstoryContainer,
} from "../components";
import React  from "react";
import { createUseStyles } from "react-jss";
import { TrailheadState } from "../models/TrailheadEngine";
import { useDocumentTitle, useQueryParams } from "../behaviors";
import { useTweekValue } from "react-tweek";
import { analytics } from "@soluto-private/generic-analytics-writer";

const useStyles = createUseStyles(() => ({
  container: {
    position: "relative",
    display: "flex",
    flexFlow: "column wrap",
    justifyContent: "center",
    alignItems: "flex-start",
    alignContent: "center",
    textAlign: "left",
    marginTop: "18px",
    fontFamily: "ATT Aleck Sans, Apercu Pro",
  },
}));

export const SimpleSetupSplashPage: React.FC = () => {
  const classes = useStyles();
  const params = useQueryParams();

  const trailheadState: TrailheadState = {
    mdn: params.get("ctn") ?? "",
    storeId: params.get("opusid") ?? "",
    repId: params.get("attuid") ?? "",
  }

  const fullstoryEnabled = useTweekValue<boolean>(
    "nashville/trailhead/fullstory/att_simple_setup_fullstory_enabled",
    false
  );

  analytics.updateProperties({experienceName: "ATTSimpleSetup", pageTitle: "ATTSimpleSetup"})

  useDocumentTitle("AT&T Simple Setup", true);
  return (
    <>
      <Header />
      <RouteContainer>
        <div className={classes.container}>
          <>
            <SimpleSetupInstructions />
            <SimpleSetupTextInitiator trailheadState={trailheadState} />
          </>
        </div>
        {fullstoryEnabled && <FullstoryContainer />}
      </RouteContainer>
    </>
  );
};
