import React, { useEffect } from "react";
import Fullstory from "react-fullstory";
import { withTweekValues } from "react-tweek";
import { setIdentityForFullstory } from "../../utilities";

interface FullstoryContainerProps {
  orgKey: string;
}

const FullstoryContainerComponent: React.FC<FullstoryContainerProps> = ({
  orgKey,
}: FullstoryContainerProps) => {
  useEffect(() => {
    const userBrowserId = localStorage.getItem("UserBrowserId");
    if (userBrowserId) {
      setIdentityForFullstory(userBrowserId);
    }
  }, []);

  return <Fullstory org={orgKey} />;
};

export const FullstoryContainer = withTweekValues({
  orgKey: "nashville/trailhead/fullstory/fullstory_org_key",
})(FullstoryContainerComponent);
